import { useEffect } from 'react';

import { gcooEventParticipatedAtom } from '@jotaiStore';
import { Icon, Portal } from 'design-system';
import { useSetAtom } from 'jotai';
import Image from 'next/image';
import Link from 'next/link';

import { GCOO_MAINTAIN_DATE } from '@components/Layout/constants';
import { Timer } from '@components/Timer';

export const GcooModal = ({ timeLimit, close }: { timeLimit: Date; close: () => void }) => {
  const setGcooEventParticipated = useSetAtom(gcooEventParticipatedAtom);

  useEffect(() => {
    setGcooEventParticipated({
      participated: true,
      maintainDate: GCOO_MAINTAIN_DATE,
    });
  }, []);

  return (
    <Portal onClickBackground={close}>
      <div className="absolute-center">
        <div className="relative">
          <Link href="/event/gcoo" onClick={close}>
            <Icon
              icon="close"
              color="gray-600"
              size={24}
              className="right-22 top-22 absolute z-10 "
              onClick={(e) => {
                close();
                e.preventDefault();
              }}
            />
            <Image
              width={326}
              height={428}
              src="/events/gcoo/gcoo-modal.png"
              alt="지쿠쿠폰 2시간 타임어택"
              className="!h-[428px] !w-[326px] !max-w-max"
            />
            <Timer
              date={timeLimit}
              className="absolute-justify-center font-Syncopate text-DTRed-400 absolute bottom-[141px] z-10 text-[26px]"
            />
          </Link>
        </div>
      </div>
    </Portal>
  );
};
