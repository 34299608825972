import { loadingAtom } from '@jotaiStore';
import { useAtomValue } from 'jotai';

import { Loading } from '@components/Loading';

export const GlobalLoading = () => {
  const loading = useAtomValue(loadingAtom);

  return <>{loading && <Loading />}</>;
};
