import { useEffect } from 'react';

import { isWebviewReadyAtom } from '@jotaiStore';
import { Portal } from 'design-system';
import { useAtomValue } from 'jotai';

import { isAppApproaching } from '@utils/Common';

import { Lottie } from '../Lottie';

export const Loading = () => {
  const isWebviewReady = useAtomValue(isWebviewReadyAtom);

  const isAppApproach = isAppApproaching();

  useEffect(() => {
    if (isAppApproach && isWebviewReady) {
      window.flutter_inappwebview.callHandler('IsVisible', 'Loading', 'true');

      return () => {
        window.flutter_inappwebview.callHandler('IsVisible', 'Loading', 'false');
      };
    }
  }, [isWebviewReady]);

  if (isAppApproach)
    return (
      <Portal isBackgroundBlack={false}>
        <div />
      </Portal>
    );

  return (
    <Portal isBackgroundBlack={true}>
      <div className="absolute-center">
        <Lottie
          path="/lotties/loading.json"
          size={{
            width: 100,
            height: 100,
          }}
        />
      </div>
    </Portal>
  );
};
