'use client';

import * as React from 'react';

import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
import { MotionValue } from 'framer-motion';
import { cn } from 'tailwind-config';

import { AppBarItem, ProgressBar, renderAppBarItems } from './components';

const appBarVariants = cva('p-8 px-16 flex justify-between bg-white min-h-[52px] w-full relative', {
  variants: {},
  defaultVariants: {},
});

interface AppBarProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof appBarVariants> {
  asChild?: boolean;
  type: 'title-left' | 'title-center';
  leftItem: React.ReactElement<typeof AppBarItem>;
  rightItem: React.ReactElement<typeof AppBarItem> | React.ReactElement<typeof AppBarItem>[]; // 배열 또는 단일 인스턴스
  text?: React.ReactNode;
  progressBarOption?: {
    showProgressBar: boolean;
    progressPercentage: number | MotionValue<number>;
  };
}

const AppBar = React.forwardRef<HTMLDivElement, AppBarProps>(
  (
    {
      className,
      text,
      type,
      leftItem,
      rightItem,
      asChild = false,
      progressBarOption,
      children,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'nav';
    return (
      <Comp id="app-bar" ref={ref} className={cn(appBarVariants({ className }))} {...props}>
        {type === 'title-left' ? (
          <div className="text-new-Sub-Title flex items-center pl-8">{text}</div>
        ) : (
          <>
            <div className="flex items-center">{renderAppBarItems(leftItem)}</div>
            <div className="text-new-Section-Title absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%]">
              {text}
            </div>
          </>
        )}
        <div className="flex items-center gap-12">{renderAppBarItems(rightItem)}</div>
        {progressBarOption && progressBarOption.showProgressBar && (
          <ProgressBar progressPercentage={progressBarOption.progressPercentage} />
        )}
        {children}
      </Comp>
    );
  },
);

AppBar.displayName = 'AppBar';

export { AppBar, AppBarItem, appBarVariants };
