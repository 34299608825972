import { useEffect } from 'react';

import { LogEvent } from '@analytics';
import { NewIcon, Spacer } from 'design-system';
import { useRouter } from 'next/router';

import { isAppApproaching } from '@utils/Common';

const isAppApproach = isAppApproaching();

let isClosed = false;

export const ReservationReturnBanner = ({
  pvData,
  close,
}: {
  pvData: PageView;
  close: () => void;
}) => {
  const router = useRouter();

  useEffect(() => {
    LogEvent.매출.showReservationReturn();
  }, []);

  if (!pvData.academy_name || isClosed) return null;

  return (
    <div
      className="absolute-justify-center bg-new-gray-900 fixed z-[100] w-[calc(100%-32px)] max-w-[468px] cursor-pointer rounded-[16px] p-20 text-white"
      style={{
        bottom: isAppApproach ? 16 : 16 + 82,
      }}
      onClick={() => {
        LogEvent.매출.clickReservationReturn();
        if (pvData.page_location) {
          router.push(pvData.page_location);
        } else if (pvData.academy_id) router.push(`/academy/${pvData.academy_id}`);
      }}
    >
      <NewIcon
        icon="x-outlined"
        size={24}
        className="fill-new-white absolute right-20 top-20"
        onClick={(e) => {
          e.stopPropagation();
          close();
          isClosed = true;
          LogEvent.매출.closeReservationReturn();
        }}
      />
      <h2 className="text-new-Section-Title">
        최근 {pvData.academy_name.split('_')[1] ?? ''}학원을 찾아봤어요 👀
      </h2>
      <Spacer className="h-2" />
      <div className="flex items-center gap-2">
        <p className="text-new-Body2-bold text-new-DTYellow-400">이어서 보기</p>
        <NewIcon icon="arrowright-outlined" size={16} className="fill-new-DTYellow-400" />
      </div>
    </div>
  );
};
