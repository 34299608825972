import { useEffect } from 'react';

import { DEFAULT_LOCATION } from '@constants/defaultValue';

import { useEmit } from '../../use-emit';

/**
 * 앱에서 receiveCurrentLocation라는 이벤트 리스너를 통해 위치를 받아오는 hook
 * 위치를 요청하는 방법에는
 * 1. window.flutter_inappwebview.callHandler('SendLocation');
 * 2. window.flutter_inappwebview.callHandler('SendLocationSilently');
 * 가 있으며 모두 해당 hook을 통해 위치를 받아온다.
 */
export const useReceiveAppLocation = () => {
  const { emitWithValue } = useEmit('appLocation');

  useEffect(() => {
    if (typeof window === 'undefined') return;

    window.addEventListener('receiveCurrentLocation', getAppLocation);

    return () => {
      window.removeEventListener('receiveCurrentLocation', getAppLocation);
    };
  }, []);

  const getAppLocation = async (e: any) => {
    // 앱에서 소수점 4자리까지만 파싱해서 가져옴
    const { latitude, longitude, isLocationDenied, isNotDetermined } = e.detail.location;

    let userLocation: null | LocationType = null;
    let userLocationPermission: LocationPermission = '';

    // 앱에서 prompt인 경우
    if (isNotDetermined) {
      userLocationPermission = 'prompt';
      userLocation = null;
    } else if (isLocationDenied) {
      userLocationPermission = 'denied';
      userLocation = DEFAULT_LOCATION;
    } else {
      userLocationPermission = 'granted';
      userLocation = {
        latitude,
        longitude,
      };
    }
    emitWithValue({
      result: 'success',
      location: userLocation as any,
      locationPermission: userLocationPermission as any,
    });
  };
};
