import { useEffect } from 'react';

import { isWebviewReadyAtom } from '@jotaiStore';
import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';

import { isAppApproaching } from '@utils/Common';

import { BOTTOM_NAVBAR_ENABLED_PATHS, BOTTOM_NAV_BAR_ITEMS } from '../BottomNavbar';

const useGetCurrentNavbarState = () => {
  const router = useRouter();
  const { pathname } = router;

  const currentIndex = BOTTOM_NAVBAR_ENABLED_PATHS.findIndex((item) => item === pathname);
  const showBottomNavbar = currentIndex !== -1;

  return {
    currentIndex,
    showBottomNavbar,
  };
};

const isAppApproach = isAppApproaching();

export const useHandleAppNavigation = () => {
  const router = useRouter();
  const { isReady, pathname } = router;
  const isWebviewReady = useAtomValue(isWebviewReadyAtom);
  const { currentIndex, showBottomNavbar } = useGetCurrentNavbarState();

  useEffect(() => {
    if (isReady && isWebviewReady && isAppApproach) {
      window.flutter_inappwebview.callHandler('TabChange', currentIndex);
    }
  }, [isReady, isWebviewReady, pathname, isAppApproach, currentIndex]);

  useEffect(() => {
    if (!isAppApproach) return;
    window.addEventListener('receiveTabbarIndex', appNavigateEvent);
    return () => {
      window.removeEventListener('receiveTabbarIndex', appNavigateEvent);
    };
  }, [isAppApproach]);

  console.log('isWebviewReady', isWebviewReady, isAppApproach);

  useEffect(() => {
    if (
      !isWebviewReady ||
      !isAppApproach ||
      !window.flutter_inappwebview ||
      router.pathname.includes('purchase')
    )
      return;

    console.log('showBottomNavbar', showBottomNavbar);

    if (showBottomNavbar) {
      window.flutter_inappwebview.callHandler('IsVisible', 'BottomNavbar', 'true');
    } else {
      window.flutter_inappwebview.callHandler('IsVisible', 'BottomNavbar', 'false');
    }
    return () => {
      window.flutter_inappwebview.callHandler('IsVisible', 'BottomNavbar', 'false');
    };
  }, [isAppApproach, showBottomNavbar, router.pathname, isWebviewReady]);

  const appNavigateEvent = async (e: any) => {
    const tabbarIndex = Number(e.detail.tabbarIndex);
    await router.replace(BOTTOM_NAV_BAR_ITEMS[tabbarIndex].path);
    window.flutter_inappwebview.callHandler('TransitionAnimation');
  };
};
